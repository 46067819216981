/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "dyslexiaFontXpAPI",
            "endpoint": "https://zjuuemhd5l.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "dyslexiafontxp-20220413175148-hostingbucket-master",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://dyslexiafontxp-20220413175148-hostingbucket-master.s3-website-ap-northeast-1.amazonaws.com"
};


export default awsmobile;
